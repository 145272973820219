import React from 'react'

import Nav from './nav.js'
import Footer from './footer.js'

import './style.css'

const Blogs = () => {

    /*
        Get all blogs
            Title, Author, Tags, Date Created, _Id
    */
    return (
        <div>
            <Nav/>
            
            {Footer}
        </div>
    )
}

export default Blogs