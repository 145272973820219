import React from 'react'
import Nav from './nav.js'
import Footer from './footer.js'
import './style.css'
const Home = () =>{

    return(
        <div>
            <Nav/>
            <div className="home_container">
                <h2>New content posted weekly</h2>
            </div>

            {Footer}
        </div>
    )
}

export default Home