import React from 'react'

import Nav from './nav.js'
import Footer from './footer.js'
import './style.css'

import Image1 from '../images/facebook.png'
import Image2 from '../images/youtube.png'
import Image3 from '../images/instagram.png'
import Image4 from '../images/chi.png'
import Image5 from '../images/global.png'
const Media = () =>{
    
    return(
        <div className="">
            <Nav/>
            <div className="media_container">
                <p className="inq">Any business enquiries, please email me at dummyartistkay@yahoo.com</p>
                <center>
                    <h1>Social Media Links</h1>
                </center>
            <div className="media_buttons">
                <a href="mailto:dummyartistkay@yahoo.com" className="link2"> <img src={Image5} alt="" className="link2" /></a> 
                <a href="https://www.youtube.com/@Earthlycousin" className="link2"> <img src={Image2} alt="" className="link2" /></a>
                <a href="/" className="link2"> <img src={Image4} alt="" className="link2"/></a>
                <a href="http://instagram.com/dummyartistkay" className="link2"><img src={Image3} alt="" className="link2" /></a>
                <a href="https://www.facebook.com/DummyArtistKay/" className="link2"> <img src={Image1} alt="" className="link2"/></a>
            </div>  

            <center>
                <h1>7 months postpartum BAWDY</h1>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/FimO88Go7RM?si=B7z2K_ZXNhCpe4aR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h1>Pregnancy Vlog | 40+ weeks, yoga, 🎁</h1>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/imHE2n3JBS8?si=-wlw01uSqs6fKXkJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
             </center>
            </div>
            <Footer/>
        </div>
    )
}

export default Media