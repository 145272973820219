import React from 'react'
import './App.css';
import Home from './components/native/home.jsx'
import About from './components/native/about.jsx'
import Media from './components/native/media.jsx'
import Blogs from './components/native/blogs.jsx'

import {
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <div>
      <Routes>
        <Route element={<Home/>} path="/About"/>
        <Route element={<About/>} path="/"/>
        <Route element={<Media/>} path="/Media"/> 
        <Route element={<Blogs/>} path="/Blogs"/> 
      </Routes>
    </div>
  );
}

export default App;
