import React from 'react'
import './style.css'
import { useNavigate } from "react-router-dom";
import Image1 from '../images/IMG_1234.png'
const Nav = () => {
    let navigate = useNavigate();
    return(
        <div className="nav_container">
            <div className="logo">
                <img className="logoname" src={Image1} alt="" />
            </div>
            <div className="nav_btn_container">
                {
                //<button className="nav_btn" onClick={(e)=>{e.preventDefault();navigate('/')}}>Home</button>
                //<button className="nav_btn" onClick={(e)=>{e.preventDefault();navigate('/Blogs')}}>Earthly Cousin</button>
                }
                <button className="nav_btn" onClick={(e)=>{e.preventDefault();navigate('/')}}>About</button>
                <button className="nav_btn" onClick={(e)=>{e.preventDefault();navigate('/Media')}}>Media</button>
            </div>
        </div>
    )
}

export default Nav