import React from 'react'

const Footer  = () => {

    return(
        <div className="footer">
            All Rights Reserved 2024 © EarthlyCousin
        </div>
    )
}

export default Footer