import { useNavigate } from "react-router-dom";
import React from 'react'
import Nav from './nav.js'
import Footer from './footer.js'
import './style.css'
const About = () =>{

    let navigate = useNavigate();
    return(
        <div>
            <Nav/>
            <div className="about_container">
                <div className="about">
               <h1> Hey Earthly Cousins! </h1>
                <br />
                <br />
                My name is Kayla and I am here to create value in connections with individuals and not just on a flat surface, but through the stimulus of a color palette that dances upon people and landscapes, evoking emotions and inspiring creativity. My approach is not limited to a one-dimensional exchange, but rather a multi-sensory experience that engages the viewer on a deeper level. By leveraging the power of color, texture, and form, I aim to foster meaningful connections and evoke a sense of wonder and awe in those who experience my work. 
                <br />
                <br />
                Any business enquiries, please email me at dummyartistkay@yahoo.com
                <br />
                If you like what you see, feel free to check out my other work at 
                <button className="nav_btn" onClick={(e)=>{e.preventDefault();navigate('/Media')}}>Social Media</button>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default About